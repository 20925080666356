import React from "react";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const Experience = () => {
  return (
    <div>
      <NavBar />
      <div class=" mt-28 justify-center items-center flex">
        <h1 class="bg-black-200 text-center text-3xl">My Work History</h1>
      </div>

      <section class="relative min-h-screen h-1/5 flex flex-col justify-center bg-gray-100 overflow-hidden mb-26">
        <div class="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
          <div class="flex flex-col justify-center divide-y divide-slate-200 [&>*]:py-16">
            <div class="w-full max-w-3xl mx-auto">
              <div class="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
                <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
                  <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                    <svg
                      class="fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="10"
                    >
                      <path
                        fill-rule="nonzero"
                        d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
                      />
                    </svg>
                  </div>

                  <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow hover:scale-110 transition">
                    <div class="flex items-center justify-between space-x-2 mb-1">
                      <div class="font-bold text-slate-900">
                        <Link to="https://www.solvaxion.com/">Solvaxion</Link>
                      </div>
                      <time class="text-xs font-medium text-indigo-500">
                        Aug 2023 - Current
                      </time>
                    </div>
                    <div class="text-slate-500">Systems Engineer</div>
                  </div>
                </div>

                <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
                  <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                    <svg
                      class="fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="10"
                    >
                      <path
                        fill-rule="nonzero"
                        d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
                      />
                    </svg>
                  </div>

                  <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow hover:scale-110 transition">
                    <div class="flex items-center justify-between space-x-2 mb-1">
                      <div class="font-bold text-slate-900">
                        <Link to="https://www.sidetrade.com/">Sidetrade</Link>
                      </div>
                      <time class="text-xs font-medium text-indigo-500">
                        Jan 2023 - May 2023
                      </time>
                    </div>
                    <div class="text-slate-500">Devops Engineer</div>
                  </div>
                </div>

                <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
                  <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                    <svg
                      class="fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="10"
                    >
                      <path
                        fill-rule="nonzero"
                        d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
                      />
                    </svg>
                  </div>

                  <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow hover:scale-110 transition">
                    <div class="flex items-center justify-between space-x-2 mb-1">
                      <div class="font-bold text-slate-900">
                        <Link to="https://www.amazon.ca/">Amazon</Link>
                      </div>
                      <time class="text-xs font-medium text-indigo-500">
                        May 2020 – Dec 2022
                      </time>
                    </div>
                    <div class="text-slate-500">Devops | Cloud Engineer</div>
                  </div>
                </div>

                <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
                  <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                    <svg
                      class="fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="10"
                    >
                      <path
                        fill-rule="nonzero"
                        d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
                      />
                    </svg>
                  </div>

                  <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow hover:scale-110 transition">
                    <div class="flex items-center justify-between space-x-2 mb-1">
                      <div class="font-bold text-slate-900">
                        <Link to="https://cwg-plc.com/">
                          Computer Warehouse Plc
                        </Link>
                      </div>
                      <time class="text-xs font-medium text-indigo-500">
                        Apr 2013 - Jan 2015
                      </time>
                    </div>
                    <div class="text-slate-500">Software Developer</div>
                  </div>
                </div>

                <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group ">
                  <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                    <svg
                      class="fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="10"
                    >
                      <path
                        fill-rule="nonzero"
                        d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
                      />
                    </svg>
                  </div>

                  <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow hover:scale-110 transition">
                    <div class="flex items-center justify-between space-x-2 mb-1">
                      <div class="font-bold text-slate-900">
                        Advanced Solution Ltd
                      </div>
                      <time class="text-xs font-medium text-amber-500">
                        Nov 2010 - Mar 2013
                      </time>
                    </div>
                    <div class="text-slate-500">IT Support Technician</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Experience;
