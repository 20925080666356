import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Phone = () => {
  return (
    <div className=" mt-20 flex justify-center items-center  rounded-full">
      <FontAwesomeIcon icon={faPhone} className="mr-2" beatFade />
      <span className="text-gray-900 font-semibold">1 403 850 3304</span>
    </div>
  );
};

export default Phone;
