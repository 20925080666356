import React, { Component } from "react";
import { Router, Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./component/Home";
import Experience from "./component/Experience";
import Contact from "./component/Contact";
import Login from "./component/Login";
import Projects from "./component/Projects";
import SoftwareSkills from "./component/SoftwareSkills";
import Skills from "./component/Skills";
import AboutMe from "./component/AboutMe";

function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutme" element={<AboutMe />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/login" element={<Login />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/software" element={<SoftwareSkills />} />
            <Route path="/skills" element={<Skills />} />
            {/* <Route path="*" element={<NoPage />} /> */}
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
