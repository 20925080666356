import React from "react";
import {
  azure,
  aws,
  terraform,
  cpp,
  sysprog,
  java,
  devops,
  postgres,
  mysql,
  mongodb,
  bash,
  github,
  jenkins,
  nmap,
  wireshark,
  oracle,
  docker,
  nginx,
  caddy,
  grafana,
  kubernetes,
  gitlab,
  bitbucket,
} from "../assets";

const techStack = [
  { src: azure, alt: "Azure" },
  { src: aws, alt: "AWS" },
  { src: terraform, alt: "Terraform" },
  { src: cpp, alt: "C++" },
  { src: sysprog, alt: "System Programming" },
  { src: java, alt: "Java" },
  { src: devops, alt: "DevOps" },
  { src: postgres, alt: "PostgreSQL" },
  { src: mysql, alt: "MySQL" },
  { src: mongodb, alt: "MongoDB" },
  { src: bash, alt: "Bash" },
  { src: github, alt: "GitHub" },
  { src: jenkins, alt: "Jenkins" },
  { src: nmap, alt: "Nmap" },
  { src: wireshark, alt: "Wireshark" },
  { src: oracle, alt: "Oracle" },
  { src: docker, alt: "Docker" },
  { src: nginx, alt: "NGINX" },
  { src: caddy, alt: "Caddy" },
  { src: grafana, alt: "Grafana" },
  { src: kubernetes, alt: "Kubernetes" },
  { src: gitlab, alt: "GitLab" },
  { src: bitbucket, alt: "Bitbucket" },
];

function Partner() {
  return (
    <div className="mt-16 ">
      <div class="mb-24">
        <h1 className="mt-4 text-center text-2xl md:text-3xl text-black-200">
          Tech Stack
        </h1>
      </div>
      <div className="bodyP ">
        <div className="inline-flex w-full flex-nowrap overflow-hidden bg-white [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
          <ul className="brands-wrapper">
            {techStack.map((tech, index) => (
              <li key={index}>
                <img src={tech.src} width="250" height="100" alt={tech.alt} />
              </li>
            ))}
          </ul>
          <ul className="brands-wrapper" aria-hidden="true">
            {techStack.map((tech, index) => (
              <li key={index}>
                <img src={tech.src} width="250" height="100" alt={tech.alt} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Partner;
