import React, { useState, useRef } from "react";
import NavBar from "./NavBar";
import { email } from "../assets";
import emailjs from "@emailjs/browser";
import Footer from "./Footer";

function Contact() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_cp2avxd", "template_7qudxmp", form.current, {
        publicKey: "GcTGmNr4dSfmPx9cw",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();
          setShowConfirmation(true);
          setTimeout(() => {
            setShowConfirmation(false);
          }, 3000);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <NavBar />

      <div className="container mx-auto px-4 py-12 md:py-24 flex flex-col items-center">
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-8 md:mb-12">
          Get in Touch
        </h1>
        <div className="w-full max-w-4xl flex flex-col md:flex-row justify-center items-center gap-8 border border-blue-500 p-4 rounded-lg bg-white">
          <div className="md:w-1/2 rounded-lg overflow-hidden shadow-md bg-white transform hover:scale-105 transition-transform duration-300">
            <img src={email} alt="Email" className="w-full h-auto" />
          </div>
          <form
            className="md:w-1/2 mx-4 my-8 md:my-0 md:mx-8 flex flex-col items-center"
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="mb-4 w-full">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Your Name
              </label>
              <input
                type="text"
                id="name"
                name="from_name"
                className="input-field rounded-lg"
                placeholder="Your name here..."
                required
              />
            </div>
            <div className="mb-4 w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Your Email
              </label>
              <input
                type="email"
                id="email"
                name="user_email"
                className="input-field rounded-lg"
                placeholder="Your email address here..."
                required
              />
            </div>
            <div className="mb-4 w-full">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Your Message
              </label>
              <textarea
                id="message"
                rows="4"
                name="message"
                className="input-field rounded-lg"
                placeholder="Leave a comment..."
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="submit-button bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
            {showConfirmation && (
              <div className="confirmation mt-2 text-green-600">
                Email sent successfully!
              </div>
            )}
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
