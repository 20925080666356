import React, { useState } from "react";
import { Custom } from "../assets/";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className=" dark:bg-gray-900 fixed w-full z-20 top-0 border-b bg-blue-300">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center space-x-4">
            <img
              className="h-12 w-auto hover:fb-scale-150"
              src={Custom}
              alt="Custom Logo"
            />
            {/* <span className="text-2xl font-semibold text-dark-100">
              Evergreen
            </span> */}
          </div>
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="block text-gray-900 dark:text-white focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
          <div className="hidden md:flex md:items-center md:justify-center md:space-x-4 flex-grow">
            <ul className="flex space-x-4">
              <li>
                <Link
                  to="/"
                  className="text-gray-900 dark:text-white hover:text-blue-700"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutme"
                  className="text-gray-900 dark:text-white hover:text-blue-700"
                >
                  AboutMe
                </Link>
              </li>
              <li>
                <Link
                  to="/experience"
                  className="text-gray-900 dark:text-white hover:text-blue-700"
                >
                  Experience
                </Link>
              </li>
              <li>
                <Link
                  to="/projects"
                  className="text-gray-900 dark:text-white hover:text-blue-700"
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-gray-900 dark:text-white hover:text-blue-700"
                >
                  ContactMe
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden">
            <div className="flex flex-col space-y-4">
              <Link
                to="/"
                className="text-gray-900 dark:text-white hover:text-blue-700"
              >
                Home
              </Link>
              <Link
                to="/aboutme"
                className="text-gray-900 dark:text-white hover:text-blue-700"
              >
                AboutMe
              </Link>
              <Link
                to="/experience"
                className="text-gray-900 dark:text-white hover:text-blue-700"
              >
                Experience
              </Link>
              <Link
                to="/projects"
                className="text-gray-900 dark:text-white hover:text-blue-700"
              >
                Projects
              </Link>
              <Link
                to="/contact"
                className="text-gray-900 dark:text-white hover:text-blue-700"
              >
                Contact
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
