import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

const TimelIne = () => {
  return (
    <div
      className=" items-center justify-center bg-gray-60"
      style={{ marginTop: "110px" }}
    >
      <div class="mb-10 ">
        <p className="text-center text-2xl md:text-3xl ">Education Timeline</p>
      </div>
      <ol class="items-center  justify-center sm:flex ">
        <li class="relative mb-6 sm:mb-0 flex flex-col items-center justify-center">
          <div class="flex items-center justify-center">
            <div class="z-0 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="w-7 h-7 text-blue-500"
              />
            </div>
            <div class="sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
          </div>
          <div class="mt-3 sm:pe-8 flex flex-col items-center justify-center sm:justify-start">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
              Universal college of technology
            </h3>
            <p class="block mb-2 text-sm font-normal leading-none  dark:text-gray-700">
              September 2, 2005: Engineering Technology
            </p>
          </div>
        </li>

        <li class="relative mb-6 sm:mb-0 flex flex-col items-center justify-center">
          <div class="flex items-center justify-center">
            <div class="z-0 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="w-7 h-7 text-blue-500"
              />
            </div>
            <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
          </div>
          <div class="mt-3 sm:pe-8 flex flex-col items-center justify-center sm:justify-start">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
              Yaba College of Technology
            </h3>
            <time class="block mb-2 text-sm font-normal leading-none  dark:text-gray-500">
              September 23, 2012: Electrical Engineer
            </time>
          </div>
        </li>

        <li class="relative mb-6 sm:mb-0 flex flex-col items-center justify-center">
          <div class="flex items-center justify-center">
            <div class="z-0 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="w-7 h-7 text-blue-500"
              />
            </div>
            <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
          </div>
          <div class="mt-3 sm:pe-8 flex flex-col items-center justify-center sm:justify-start">
            <h3 class="text-lg text-center font-semibold text-gray-900 dark:text-white">
              Southern Alberta Institute of Technology(S.A.I.T)
            </h3>
            <p class="block mb-2 text-sm font-normal leading-none  dark:text-gray-500">
              August 19, 2022: Information Technology
            </p>
          </div>
        </li>

        <li class="relative mb-6 sm:mb-0  flex flex-col items-center justify-center">
          <div class="flex items-center">
            <div class="z-0  flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="w-7 h-7 text-blue-500"
              />
            </div>
            <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
          </div>
          <div class="mt-3 sm:pe-8 items-center justify-center">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
              University of Lethbridge
            </h3>
            <p class="block mb-2 text-sm font-normal leading-none  dark:text-gray-500">
              April 5, 2025: Computer Science
            </p>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default TimelIne;
