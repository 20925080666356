import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { aboutme } from "../assets";
import resumePdf from "../assets/resume.pdf";

const AboutMe = () => {
  return (
    <div>
      <NavBar />
      <div className="mt-11 ">
        <section className="bg-white dark:bg-gray-900">
          <div className="container mx-auto px-4 py-8 lg:py-16">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
              <div className="lg:col-span-7 flex flex-col justify-center items-center">
                <h1 className=" text-4xl md:text-5xl xl:text-6xl font-mono font-extrabold text-center  lg:text-left mt-4 lg:mb-0 dark:text-white mx-18  ">
                  Eze Adiele
                </h1>
                <p className="  text-base md:text-lg lg:text-xl font-light  dark:text-gray-400">
                  My Name is Eze Adiele and I have been in the Information
                  technology space for over a decade and I specialize in
                  designing and maintaining a resilient architecture for
                  clients.
                  <br />
                  In the last 5 years, I have dedicated my passion to helping
                  companies implement and design such
                  <br /> architecture to make them profitable by 30%.
                  <br /> My real strength is my ability to truly understand my
                  clients' needs and use the plethora of skills
                  <br /> and the appropriate toolchain to implement their needs.
                  <br /> I pride myself in providing commitment and dedication
                  to ensure this goal and I am interested in <br /> a company
                  where I can add value over a long period of time, taking any
                  role as a career rather <br />
                  than a "fill-in", producing a positive return on investment by
                  joining a strong team with great culture.
                </p>
                {/* <div className="text-center lg:text-left mt-4">
                  If you prefer a soft copy resume. Please download it here.
                  <a
                    href={resumePdf}
                    download="Eze_Adiele_Resume.pdf"
                    className="text-blue-600 hover"
                  >
                    Download Resume (PDF)
                  </a>
                </div> */}
              </div>
              <div className="lg:col-span-5 flex justify-center lg:justify-end mt-11">
                <img
                  src={aboutme}
                  alt="mockup"
                  className=" card-zoom-image rounded-lg lg:hover:scale-110 transition duration-300"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutMe;
