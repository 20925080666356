import React from "react";
import {
  cpp,
  devops,
  sysprog,
  react,
  oracle,
  linux,
  database,
  docker,
  terraform,
  github,
  nginx,
} from "../assets";

const Skills = () => {
  return (
    <div class="mx-5 rounded-lg">
      <div class="mt-40 justify-center text-center ">
        <p class="text-3xl">My Expertise, My Skills</p>
      </div>
      <div class="container mb-20 lg:mt-20  mx-auto grid grid-cols-1 md:grid-cols-3 gap-10  mt-12 text-1xl  justify-center items-center">
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={cpp} class="rounded-lg" />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">C++</h2>
          <p class="leading-related text-based">
            Proficient in algorithm designs that drives search engine and
            implementating such, using the concepts of Object oriented
            programming(O.O.P).
          </p>
        </div>
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={docker} class="rounded-full" />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
            Docker
          </h2>
          <p class="leading-related text-based">
            Proficient in docker and its use in packaging applications or
            building custom docker images. Leveraging Kubernetes to manage the
            lifecycle of docker and related tools, including vault.
          </p>
        </div>
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={devops} />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
            Devops
          </h2>
          <p class="leading-related text-based">
            Experienced in DevOps and Cloud practices, specializing in automated
            deployment leveraging CI/CD pipelines and it's toolchain to
            streamline the release process.
          </p>
        </div>
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={react} />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
            React
          </h2>
          <p class="leading-related text-based">
            Proficient in React framework, specializing in building modern and
            responsive user interfaces for web applications and making it
            stateful.
          </p>
        </div>
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={database} />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
            Database
          </h2>
          <p class="leading-related text-based">
            Proficient in database concept design, mapping and normalization,
            providing adminstrative support for SQL and NoSQL database either
            on-premise or cloud.
          </p>
        </div>
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={linux} />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
            Linux
          </h2>
          <p class="leading-related text-based">
            Proficient in the use of all variants of Linux, easy to stand-up and
            teardown and manage security of applications. Alter and customize
            system behaviour to manage resorces.
          </p>
        </div>
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={terraform} />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
            Terraform
          </h2>
          <p class="leading-related text-based">
            Proficient in the use of terraform and terraform cloud to easily
            deploy, manage architecture and flexibility to teardown or rebuild
            resources without affecting the architecture.
          </p>
        </div>
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={github} />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
            Github
          </h2>
          <p class="leading-related text-based">
            Proficient in the use of github and gitlab as single source of truth
            for all deployment, storage and versioning. Also proficient with
            bitbucket and related toolchain.
          </p>
        </div>
        <div class="border border-gray-200 bg-gray-100 p-2 rounded h-auto">
          <div class="w-10 h-10 inline-flex items-center justify-center  bg-purple-100 text-purple-500 mb-4">
            <img src={nginx} />
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
            LoadBalancer
          </h2>
          <p class="leading-related text-based">
            Proficient in the use of custom or cloud provided loadbalancer to
            configure https accross the loadbalancers or proxy-servers to ensure
            high availability.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Skills;
