import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Carousel from "./Carousel";
import Slider from "./Slider";
import TimelIne from "./TimelIne";
import Partner from "./Partner";
import Phone from "./Phone";
import Skills from "./Skills";

const Home = () => {
  return (
    <>
      <Phone />
      <NavBar />

      <Carousel />
      {/* <div className="my-20"></div>

      <div className="my-20"></div> */}
      {/* <TimelIne /> */}
      <Skills />
      <Partner />
      <Footer />
    </>
  );
};

export default Home;
