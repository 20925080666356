import React from "react";
import { backgroundCarousel } from "../assets";

const Carousel = () => {
  return (
    <div className="relative ">
      <p className="mt-6 text-center text-3xl md:text-3xl text-black-200">
        My Services
      </p>
      <img
        src={backgroundCarousel}
        alt="background"
        className="absolute inset-0 w-full h-full object-cover my-20"
      />
      <div className="relative container   mx-auto">
        <div className=" grid md:grid-cols-2 lg:grid-cols-4 sm:grid-cols-1 gap-4   mx-auto mt-28">
          <div className=" bg-opacity-50 max-w-sm p-6 m-4 flex flex-col justify-center items-center hover:scale-110 transition  border  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ring-4  hover:ring-2 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white text-center">
              Full Stack Developer
            </h5>
            <p className="mb-3 font-normal text-white dark:text-gray-400 text-center">
              Provides concept design and implementation for wholly integrated
              website design
            </p>
          </div>

          <div className=" bg-opacity-50 max-w-sm p-6 m-4 flex flex-col justify-center items-center hover:scale-110 transition  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ring-4  hover:ring-2 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white text-center">
              Devops | Cloud Engineering
            </h5>
            <p className="mb-3 font-normal text-white dark:text-gray-400 text-center">
              Specializes in designing,deploying,
              <br />
              securing and maintaining cloud infrastructure, to ensure high
              availability.
            </p>
          </div>

          <div className=" bg-opacity-50 max-w-sm p-6 m-4 flex flex-col justify-center items-center hover:scale-110 transition  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ring-4  hover:ring-2 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white text-center">
              Consultancy Services
            </h5>
            <p className="mb-3 font-normal text-white dark:text-gray-400 text-center">
              Provide consultancy services to clients in software development,
              cloud services,system programming and Devops Engineering.
            </p>
          </div>

          <div className=" bg-opacity-50 max-w-sm p-6 m-4 flex flex-col justify-center items-center hover:scale-110 transition  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ring-4  hover:ring-2 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white text-center">
              Database Administration
            </h5>
            <p className="mb-3 font-normal text-white dark:text-gray-400 text-center">
              Provides installation, configuration, database design, migration,
              performance monitoring, security, and troubleshooting
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
